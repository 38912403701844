<template>
  <div class="news">
    <Nav nums='2' isColor='#fff'/>
    <div class="banner">
       <Video :bannerArr='bannerImg'/>
        <div class="banner_cont">
            <div class="title">
                {{bannerNew.name}}
                <hr>
            </div>
            <div class="contBox">
                <div class="content">
                    <span class="nbsp">空白</span>{{bannerNew.depict}}
                </div>
                <router-link :to="{path:'/newsDetails',query:{id:bannerNew.id,index:0,type:1}}">
                    <div class="more">
                        <span>查看更多</span>
                        <img src="../assets/check.png" alt="">
                    </div>
                </router-link>
            </div>
        </div>
    </div>
    <div class="news_list">
        <div class="newsBox">
            <div class="newsCont" v-for="(item,index) in newsList" :key="index">
                <div class="cont_title">
                   <div>{{item.name}}</div>
                </div>
                <div class="contBox">
                    <router-link :to="{path:'/newsDetails',query:{id:item.id}}"><div class="cont_img" :style="'background: url(' + item.images + ') no-repeat center center;background-size: 100% 100%;'">
                    </div></router-link>
                    <div class="content">
                        <div class="textBox">
                            <span class="nbsp">空白</span>{{item.depict}}
                        </div>
                        <router-link :to="{path:'/newsDetails',query:{id:item.id,index:index+1,type:1}}">
                            <div class="more">
                            <span>查看更多</span>
                            <img src="../assets/check.png" alt="">
                        </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="news_flash">
            <div class="flash" v-for="(item,index) in flash" :key="index">
                <router-link :to="{path:'/newsDetails',query:{id:item.id,index,type:2}}">
                    <div class="flashTop" :style="'background: url(' + item.images + ') no-repeat center center;background-size: 100% 100%;'">
                    <div class="flashTitle"> {{item.name}}</div>
                </div>
                <div class="flashCont">
                    <span class="nbsp">空白</span>{{item.depict}}
                </div>
                </router-link>
            </div>
        </div>
    </div>
    <Floor/>
  </div>
</template>

<script>
import Nav from './nav.vue'
import Floor from './floor.vue'
import { imgHttp, request } from '../apis/http'
import moment from 'moment'
import Video from '../components/video.vue'
export default {
  components: {
    Nav,
    Floor,
    Video
  },
  data () {
    return {
    // 轮播新闻
      bannerNew:{},
      bannerImg:[],
    //   上部分新闻
      newsList: [],
    //   下部分新闻
      flash: [],
      bottomImg:[],
      searchTitle:'',
      bannerShow:true
    }
  },
  beforeMount() {
        this.newList()
        this.TopnewList()
        this.bigBanner()
        scrollTo(0, 0)
        this.searchTitle = this.$route.query.title
  },
  methods: {
    // 下部分新闻
    async newList(){
        const res = await request({
            url:'/api/index/newslist?type=1'
        })
        if (this.$route.query.title === undefined){
            this.bannerShow = true
            const newFlash = res.data.data.map(i => {
            i.images = imgHttp + i.images
            i.updatetime = moment(i.updatetime * 1000).format('YYYY.MM.DD')
            return i
           })
            localStorage.setItem('part2', JSON.stringify(newFlash))
            this.flash = newFlash
        } else {
            this.bannerShow = false
             const searchArr = res.data.data.filter(item => {
             item.images = imgHttp + item.images
             item.updatetime = moment(item.updatetime * 1000).format('YYYY.MM.DD')
            return item.name.indexOf(this.searchTitle) !== -1
        })
            localStorage.setItem('part2', JSON.stringify(searchArr))
            this.flash = searchArr
        }
    },
    mouseleaveImg (index) {
      this.active = 'transition: transform 1b ngs;transform: scale(1);'
      let show = JSON.parse(JSON.stringify(this.isShow))
      show = []
      show[index] = !show[index]
      this.isShow = show
    },
    // 上部分新闻
    async TopnewList(){
        const res = await request({
            url:'/api/index/newslist?type=2'
        })
         if (this.$route.query.title === undefined){
            this.bannerShow = true
            const newsList = res.data.data.map(i => {
            i.images = imgHttp + i.images
            i.updatetime = moment(i.updatetime * 1000).format('YYYY.MM.DD')
            return i
           })
           localStorage.setItem('part1', JSON.stringify(newsList))
           this.newsList = newsList.slice(1)
           this.bannerNew = newsList[0]
        } else {
            this.bannerShow = false
             const searchArr = res.data.data.filter(item => {
             item.images = imgHttp + item.images
             item.updatetime = moment(item.updatetime * 1000).format('YYYY.MM.DD')
            return item.name.indexOf(this.searchTitle) !== -1
        })
            localStorage.setItem('part1', JSON.stringify(searchArr))
            this.newsList = searchArr
        }
    },
    // 大banner
    async bigBanner () {
      const res = await request({
        url: '/api/index/cover?type=7'
      })
      res.data.data.map(i => {
          if (i.images !== ''){
            i.images = i.images.split(',').map(r => {
                r = imgHttp + r
                return r
            })
          }
          if (i.videos !== ''){
              i.videos = i.videos.split(',').map(r => {
              r = imgHttp + r
              return r
          })
          }
          i.arr = [...i.videos, ...i.images]
          return i
      })
      this.bannerImg = res.data.data[0].arr
   }
  }
}
</script>
<style lang="less">
@import './fn.less';
   .navCont{
       position: fixed;
       z-index: 5000;
   }
   .banner{
       width: 100%;
       height: 46vw;
    .el-carousel .el-carousel__indicators{
        flex-direction: row;
        display: none;
    }
       .banner_cont{
           width: 50%;
           height: 41vw;
           display: flex;
           flex-direction: column;
           align-items: center;
           box-sizing: border-box;
           .vw(padding,100,50,0,50);
           background-color: rgba(255, 255, 255, 0.5);
           position: absolute;
           z-index: 100;
           top: 5.20833vw;
           left: 0;
           .title{
               color: #00538D;
               font-size: 2.604166vw;
               font-weight: 700;
               line-height: 3.125vw;
               hr{
                   width: 18.229166vw;
                   border: 1px solid #00538D;
                   background: #00538D;
                   margin-top: 1.8229166vw;
               }
           }
           .contBox{
               margin-top: 3.0104166vw;
               .headCont{
                   color: #00538D;
                   font-size: 1.3020833vw;
                   p{
                       margin-bottom: 1.04166vw;
                   }
               }
               .content{
                   width: 38.8125vw;
                   font-size: 1.04166vw;
                   color: #003B65;
                   line-height: 1.8625vw;
                   overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 8;
                    .nbsp{
                            color: transparent!important;
                        }
               }
               .more{
                   margin-top: 4vw;
                   text-align: end;
                   color: #00538D;
                   font-size: 1.04166vw;
                   display: flex;
                   justify-content: flex-end;
                   align-items: center;
                   cursor: pointer;
                   span{
                       border-bottom: 1px solid #00538D;
                   }
                   img{
                       width: 0.9895833vw;
                       height: 1.04166vw;
                       margin-left: 0.9895833vw;
                   }
               }
           }
       }
   }
   .news_list{
       width: 100%;
       display: flex;
       flex-direction: column;
       align-items: center;
       margin-bottom: 10.4166vw;
       .newsCont{
           width: 72.08333vw;
           height: 31.25vw;
           position: relative;
           margin-top: 5.7291667vw;
           .cont_title{
               position: absolute;
               left: 0;
               bottom: 0;
               width: 100%;
               height: 3.28125vw;
               background: url('../assets/icon.png');
               background-size:100% 100%;
               display: flex;
               align-items: center;
               color: #fff;
               font-size: 1.30208333vw;
               z-index: 1;
               box-sizing: border-box;
               div{
                 width: 47.1354167vw;
                 text-align: center;
               }
           }
           .contBox{
               display: flex;
              .cont_img{
                    width: 47.1354167vw;
                    height: 31.25vw;
                    overflow: hidden;
                    background-size: 100% 100%;
                    transition: background-size 1s;
                    &:hover{
                      background-size: 110% 110% !important;
                      transition: background-size 1s;
                    }
              }
              .content{
                  flex: 1;
                  height: 31.25vw;
                  overflow: hidden;
                  padding-left: 1.7708333vw;
                  padding-right: 1.5vw;
                  .textBox{
                    width: 21.6145833vw;
                      overflow: hidden;
                      margin-top: 10.4166vw;
                      color: #101218;
                      font-size: 0.9375vw;
                      line-height: 1.71875vw;
                     display: -webkit-box;
                   -webkit-box-orient: vertical;
                   -webkit-line-clamp: 6;
                   text-overflow: ellipsis;
                   .nbsp{
                        color: transparent!important;
                        }
                  }
                  .more{
                      margin-top: 4.53125vw;
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                      color: #00538D;
                      font-size: 1.04166vw;
                      span{
                       border-bottom: 1px solid #00538D;
                     }
                      img{
                          width: 0.98958333vw;
                          height: 1.04166vw;
                          margin-left: 0.9375vw;
                      }
                  }
              }
           }
       }
       .news_flash{
           display: flex;
           flex-wrap: wrap;
           justify-content: space-between;
           width: 72.08333vw;
           .flash{
               width: 35.41666vw;
               height:23.4375vw ;
               margin-top: 5.208333vw;
               overflow: hidden;
               .flashTop{
                   width: 100%;
                   height: 18.0729167vw;
                   position: relative;
                   background-size: 100% 100%;
                   transition: background-size 1s;
                   &:hover{
                     background-size: 110% 110% !important;
                     transition: background-size 1s;
                   }
                   .flashTitle{
                       position: absolute;
                       bottom: 0;
                       display: flex;
                       justify-content: center;
                       align-items: center;
                       width: 100%;
                       height: 3.5416667vw;
                       background: rgba(0, 83, 141, 0.6);
                       color: #fff;
                       font-size: 1.04166vw;
                       box-sizing: border-box;
                       overflow: hidden;
                       white-space: nowrap;
                       text-overflow: ellipsis;
                   }
               }
               .flashCont{
                   width: 100%;
                   padding: 1.04166vw 2.2916667vw 0px 1.3541667vw;
                   color: #171B1D;
                   font-size: 0.9375vw;
                   line-height: 1.71875vw;
                   box-sizing: border-box;
                   overflow: hidden;
                   display: -webkit-box;
                   -webkit-box-orient: vertical;
                   -webkit-line-clamp: 2;
                   text-overflow: ellipsis;
                   .nbsp{
                        color: transparent!important;
                    }
               }
           }
       }
   }
  @media screen and (max-width: 980px) {
      .banner{
       height: 80vw;
       background-size: 200% 200%;
       background-position: 0% 50%;
       .el-carousel{
           .vvw(margin-top,100);
       }
       .banner_cont{
           width: 100%;
           .vvw(height,550);
           text-align: center;
           top: 0;
           .title{
               .vvw(font-size,36);
               .vvw(margin-top,100);
               text-align: center;
               hr{
                   width: 50.229166vw;
                   border: 1px solid #00538D;
                   background: #00538D;
                   margin-top: 1.8229166vw;
               }
           }
           .contBox{
               width: 100%;
               margin-top: 3.0104166vw;
               .headCont{
                   color: #00538D;
                   .vvw(font-size,32);
                   p{
                       margin-bottom: 1.04166vw;
                   }
               }
               .content{
                   width: 100%;
                   .vvw(font-size,24);
                   line-height: 4.5625vw;
                   text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    line-clamp: 5;
                    -webkit-box-orient: vertical;
               }
               .more{
                   .vvw(margin-top,10);
                   .vvw(margin-bottom,0);
                   .vvw(margin-right,70);
                   justify-content: flex-end;
                   .vvw(font-size,30);
                   img{
                       width: 3.0895833vw;
                       height: 3.04166vw;
                       margin-left: 0.9895833vw;
                   }
               }
           }
       }
   }
   .news{
       height: 100%;
       display: flex;
       flex-direction: column;
       justify-content: space-between;
   }
     .news_list{
       width: 100%;
       display: flex;
       flex-direction: column;
       align-items: center;
       margin-bottom: 10.4166vw;
      .newsBox{
          width: 100%;
          .vvw(padding,1,110);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          box-sizing: border-box;
           .newsCont{
           width: 100%;
           height: 100%;
           position: relative;
           margin-top: 10.7291667vw;
           .cont_title{
               width: 100%;
               height: 7.1vw;
               background: url('');
               background:rgba(0, 83, 141, 0.6);
              .vvw(font-size,24);
               padding-left: 2.08333vw;
               box-sizing: border-box;
               top: 44.15vw;
               div{
                   .vvw(width,660);
                  overflow: hidden;
                  text-overflow:ellipsis;
                  white-space: nowrap;
               }
           }
           .contBox{
               display: flex;
               flex-direction: column;
              .cont_img{
                    width: 100%;
                    height: 51.25vw;
                    overflow: hidden;
                    background-size: 100% 100%;
                    transition: background-size 1s;
                    &:hover{
                      background-size: 110% 110% !important;
                      transition: background-size 1s;
                    }
              }
              .content{
                  flex: 1;
                  height: 31.25vw;
                  overflow: hidden;
                  padding-left: 1.7708333vw;
                  .textBox{
                    width: 100%;
                    overflow: hidden;
                    margin-top: 3.4166vw;
                    color: #101218;
                   .vvw(font-size,24);
                    line-height: 5.71875vw;
                    display: -webkit-box;
                   -webkit-box-orient: vertical;
                   -webkit-line-clamp: 4;
                   text-overflow: ellipsis;
                  }
                  .more{
                      margin-top: 3.53125vw;
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                      color: #00538D;
                   .vvw(font-size,24);
                      span{
                       border-bottom: 1px solid #00538D;
                     }
                      img{
                          width: 3.08958333vw;
                          height: 3.54166vw;
                          margin-left: 2.9375vw;
                      }
                  }
              }
           }
       }
      }
       .news_flash{
           flex-direction: column;
           flex-wrap: nowrap;
           width: 100%;
           .vvw(padding,0,110);
           box-sizing: border-box;
           .flash{
               width: 100%;
               height:100%;
               margin-top: 5.208333vw;
               overflow: hidden;
               .flashTop{
                   width: 100%;
                   height: 51.25vw;
                   .flashTitle{
                       height: 7.5416667vw;
                       background: rgba(0, 83, 141, 0.6);
                       box-sizing: border-box;
                       overflow: hidden;
                       white-space: nowrap;
                       text-overflow: ellipsis;
                       .vvw(font-size,24);
                   }
               }
               .flashCont{
                   width: 100%;
                   padding: 1.04166vw 2.2916667vw 0px 1.3541667vw;
                   color: #171B1D;
                   .vvw(font-size,24);
                   line-height: 5.71875vw;
                   box-sizing: border-box;
                   overflow: hidden;
                   display: -webkit-box;
                   -webkit-box-orient: vertical;
                   -webkit-line-clamp: 2;
                   text-overflow: ellipsis;
               }
           }
       }
   }
  }
</style>
